
































import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

export default Vue.extend({
  name: "Home",
  components: {},
  data: function() {
    return {
      numPlayers: 1,
      track: "",
      optionsSelect: ["Corta: 1Km", "Larga: 10Km"],
      players: [{ name: "" }],
      msg:""
    };
  },
  computed: {
    maxPlayer: function() {
      return this.$store.getters.getMaxPlayers;
    }
  },
  watch: {
    numPlayers: function(newValue, oldValue) {
      if (newValue > oldValue) {
        this.players.push({ name: "" });
      } else {
        this.players.pop();
      }
    }
  },
  methods: {
    onClick: function() {
      if (!this.players.some(value => value.name === "") && this.track !== "") {
        this.$store.commit("setPlayers", this.players);
        this.$store.commit(
          "setTrack",
          this.track.substring(0, this.track.indexOf(":"))
        );
        this.$router.replace({ name: "Juego" });
      }
      else{
        this.msg="No pueden haber campos vacíos."
        setTimeout(()=>this.msg="",2000)
      }
    }
  },
  beforeMount: function() {
    this.$store.dispatch("actionPokemons");
  }
});
